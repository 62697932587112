import React from 'react';
import Accordion from './Accordion'; // Adjust the path based on your project structure

const FAQs: React.FC = () => {
  const faqItems = [
    {
      title: 'What is DOGTV?',
      content: "DOGTV is a scientifically designed TV channel for dogs to keep them relaxed and entertained, but it's also THE ultimate streaming platform for dog lovers to find expert advice on dog ownership, behavioral advice, training, and veterinary tips.  We suggest you enjoy DOGTV <span class='underline'>with</span> your dog when you're home and turn on DOGTV when you need to leave your pets home alone for their health & well-being."
    },
    {
      title: 'How can I subscribe to DOGTV?',
      content: "It's easy to subscribe to DOGTV right here through our website. Simply sign up to begin your 7-day free trial today!"
    },
    {
      title: 'Is DOGTV available on all devices?',
      content: "Yes, once you have signed up and have a login and password, you can view DOGTV on many devices including smartphones, tablets, smart TVs, and computers via the web or our APPs. DOGTV is available on Roku, Amazon Fire TV, Samsung Smart TV, iOS, Android, Apple TV, Chromecast, Sling and more. DOGTV continues to add platforms so check back frequently."
    },
    {
      title: 'Can I cancel my subscription at any time?',
      content: "Yes, you can cancel your subscription at any time by logging into your account on a WEB browser and going to your account settings -> cancel subscription. You are in control of your account. No fees to cancel. No long term contracts."
    }
  ];

  return <Accordion items={faqItems} />;
};

export default FAQs;
