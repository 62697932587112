import React, { useState, useEffect } from 'react';

// HubSpot type declarations
interface HubSpotForms {
  create(options: {
    region: string;
    portalId: string;
    formId: string;
    target: string;
    [key: string]: any;
  }): void;
}

interface HubSpot {
  forms: HubSpotForms;
}

declare global {
  interface Window {
    hbspt: HubSpot;
  }
}

interface NewsletterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewsletterModal: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
  // Create a ref for the HubSpot form script
  const [formLoaded, setFormLoaded] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Load HubSpot script when modal opens
  useEffect(() => {
    if (isOpen && !scriptLoaded) {
      // Check if script is already on the page
      const existingScript = document.querySelector('script[src*="hsforms.net/forms/embed/v2.js"]');
      
      if (existingScript) {
        setScriptLoaded(true);
        return;
      }
      
      // Load HubSpot script
      const script = document.createElement('script');
      script.charset = 'utf-8';
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.async = true;
      
      script.onload = () => {
        setScriptLoaded(true);
      };
      
      document.body.appendChild(script);
      
      return () => {
        // We don't remove the script on unmount as it might be needed elsewhere
        // and removing scripts can cause issues with HubSpot
      };
    }
  }, [isOpen, scriptLoaded]);
  
  // Create the form once the script is loaded
  useEffect(() => {
    if (isOpen && scriptLoaded && !formLoaded) {
      // Small timeout to ensure hbspt is defined
      const timer = setTimeout(() => {
        if (window.hbspt) {
          try {
            window.hbspt.forms.create({
              region: 'na1',
              portalId: '22733430',
              formId: '3cdeec3f-ae9b-4db5-8f98-44a178645d6a',
              target: '#hubspot-form-container'
            });
            setFormLoaded(true);
          } catch (error) {
            console.error('Error creating HubSpot form:', error);
          }
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [isOpen, scriptLoaded, formLoaded]);

  // Reset form loaded state when modal closes
  useEffect(() => {
    if (!isOpen && formLoaded) {
      setFormLoaded(false);
    }
  }, [isOpen, formLoaded]);
  
  if (!isOpen) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999
      }}
      onClick={onClose}
    >
      <div 
        style={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '40px',
          padding: '30px',
          maxWidth: '600px',
          width: '90%',
          maxHeight: '90vh',
          overflow: 'auto',
          border: '3px solid var(--White)',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          style={{
            position: 'absolute',
            top: '15px',
            right: '20px',
            background: 'var(--Seadog-Blue)',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '18px',
            fontWeight: 'bold',
            cursor: 'pointer',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
          }}
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>
        <h3 style={{ marginBottom: '20px', color: 'var(--Seadog-Blue)', fontWeight: 'bold' }}>Join the Unleashed Family!</h3>
        <p style={{ marginBottom: '20px' }}>
          Get exclusive content, training tips, and special offers delivered straight to your inbox. Be the first to know about new shows and events!
        </p>
        <div id="hubspot-form-container">
          {!formLoaded && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '30px'
            }}>
              <div style={{
                border: '4px solid rgba(0, 0, 0, 0.1)',
                borderRadius: '50%',
                borderTop: '4px solid var(--Seadog-Blue)',
                width: '40px',
                height: '40px',
                animation: 'spin 1s linear infinite',
                marginBottom: '10px'
              }}></div>
              <p>Loading form...</p>
              <style>
                {`
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                `}
              </style>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterModal;