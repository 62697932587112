//import React from 'react'
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useOffers } from '../../hooks/useOffers'
import { Zoom } from 'react-awesome-reveal'
import 'bootstrap/dist/css/bootstrap.min.css'
import tcl from '../../assets/partnership/TCL.png'
import firetv from '../../assets/partnership/FireTV.png'
import tugo from '../../assets/partnership/tugo.png'
import blueCheck from '../../assets/blueCheck.svg'
import clapperv2 from '../../assets/clapperYellow.svg'
import couchv2 from '../../assets/couchYellow.svg'
import ribbonv2 from '../../assets/ribbonYellow.svg'
import herov1 from '../../assets/partnership/pricingBG.jpg'
import gold_standard_right from '../../assets/partnership/lac-rightimg-v2.png'
import FeaturedVideo from '../../components/FeaturedVideo'
import HeroUnleashed from '../../assets/partnership/HeroUnleashed.jpg'
import NewsletterModal from '../../components/NewsletterModal'
import PremiumPack from '../../assets/partnership/PremiumPack-v2.png'
import freeChannel from '../../assets/partnership/freeChannel-v3.png'

export const UnleashedHome: React.FC = () => {
  const { offers, offersLoading, setIsPickOfferFirstFlow, setSelectedOffer } = useOffers();
  const navigate = useNavigate();
  const offset = window.innerWidth <= 768 ? 50 : 80;
  const [isVisible, setIsVisible] = useState(false);
  const shapeDividerRef = useRef<HTMLDivElement | null>(null);
  const [isStickyVisible, setIsStickyVisible] = useState(true);
  const annualOffer = offers[0];

  const handleClick = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Inside your component:
  const [showMoreText, setShowMoreText] = useState(false);

  const toggleMoreText = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowMoreText(!showMoreText);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add this to your component
  useEffect(() => {
    // Get the more-text element and add/remove the 'visible' class based on state
    const moreTextEl = document.querySelector('.more-text');
    if (moreTextEl) {
      if (showMoreText) {
        moreTextEl.classList.add('visible');
      } else {
        moreTextEl.classList.remove('visible');
      }
    }
  }, [showMoreText]);
    
  // SEO metadata
  const LandingPageDescription = "Get unlimited access to premium dog-friendly content with your annual subscription. Stream exclusive shows, music, and dogumentaries anytime, anywhere.";

  useEffect(() => {
  document.title = 'Unleashed by DOGTV | The Ultimate Streaming Platform';
}, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (shapeDividerRef.current) {
            observer.unobserve(shapeDividerRef.current);
          }
        }
      },
      { threshold: 0.1 }
    );

    if (shapeDividerRef.current) {
      observer.observe(shapeDividerRef.current);
    }

    return () => {
      if (shapeDividerRef.current) {
        observer.unobserve(shapeDividerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]');

    const handleAnchorClick = (e: Event) => {
      e.preventDefault();

      const targetId = (e.currentTarget as HTMLAnchorElement).getAttribute('href')!.substring(1);

      // Special case for the #join link
      if (targetId === 'join') {
        setIsModalOpen(true);
        return;
      }
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const elementPosition = targetElement.offsetTop;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };

    anchors.forEach(anchor => anchor.addEventListener('click', handleAnchorClick as EventListener));

    return () => {
      anchors.forEach(anchor => anchor.removeEventListener('click', handleAnchorClick as EventListener));
    };
  }, [offset]);

  useEffect(() => {
    const handleScroll = () => {
      const startNowSection = document.getElementById('start-now');
      const rect = startNowSection?.getBoundingClientRect();
      const isInView = rect ? rect.top <= window.innerHeight && rect.bottom >= 0 : false;
      setIsStickyVisible(!isInView);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Love + DOGTV | The Ultimate Streaming Platform</title>
        <meta name="description" content={LandingPageDescription} />
        <meta property="og:title" content="Unleashed by DOGTV | The Ultimate Streaming Platform" />
        <meta property="og:description" content={LandingPageDescription} />
        <meta name="twitter:title" content="Unleashed by DOGTV | The Ultimate Streaming Platform" />
        <meta name="twitter:description" content={LandingPageDescription} />
      </Helmet>
      {/* Newsletter Modal */}
        <NewsletterModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {/* hero section - 1st*/}
      <div className='container-fluid section-with-diagonal hero-background' style={{ background: 'var(--Black)' , backgroundImage: `url(${HeroUnleashed})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', zIndex:'1000' }}>
      <div className='px-mobile py-5 fw'>
        <div className="col-md-6 d-flex align-items-center text-light px-3 py-md-5 left-column">
          <div className="d-flex flex-column">
            <h2 className='text-center text-md-start pb-md-0 pt-4 mb-0'>Introducing<br/>Unleashed by DOGTV</h2>
            <p className='text-center text-md-start py-2 fs-4 w-md-75'>The Free Streaming Channel for Every Dog Lover—From the Creators of DOGTV!</p>
            <a href="#join" className="btn btn-primary d-flex text-center text-md-start mx-auto-mobile">Sign up for Newsletter</a>
          </div>
        </div>
        <div className="col-md-6 text-end d-flex align-items-end justify-content-center pt-3 pt-md-5">

        </div>
      </div>
    </div>
    {/* end of hero section - 1st */}
    {/* Featured Video section - 2nd */}
    <div id='videos' className='container-fluid ' style={{ background: 'var(--White)' }}>
    <div className="row text-center py-5 logos-section px-mobile ">
        <div className="col-12 mx-auto align-items-center">
        <h3 className='text-center pt-4'>All Things Dog. All Day Long.</h3>
          <FeaturedVideo />
        </div>
      </div>
    </div>
    {/* end of Featured Video section - 2nd */}
    {/* what's inside section - 3rd */}
      <div className='container-fluid pt-5 ' style={{ background: 'var(--Black)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="row px-mobile">
          <div className="col-12 text-center mb-4">
            <h5 className='pt-5 pb-3' style={{color:'var( --Retriever-Gold)',}}>Unleashed by DOGTV</h5>
            <h2 className='text-light'>Enjoy a 24/7 streaming channel dedicated to everything dogs.</h2>
          </div>
          <div className="col-12 text-center">
            <div className="row text-black">
              <div className="col-md-4 col-10 my-3 mx-auto" >
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={couchv2} className="img-fluid pb-4 zoom-img" alt="couchv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3 text-light'>Expert Tips</h4>
                <p className='px-md-3 text-light w-md-75 mx-auto'>Training, behavior, and wellness advice from top vets and trainers.</p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={clapperv2} className="img-fluid pb-4 zoom-img" alt="clapperv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3 text-light'>Inspiring Stories</h4>
                <p className='px-md-3 text-light w-md-75 mx-auto'>Heartfelt tales celebrating the human-animal bond.</p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={ribbonv2} className="img-fluid pb-4 zoom-img" alt="ribbonv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3 text-light'>Adoption Show</h4>
                <p className='px-md-3 text-light w-md-75 mx-auto'>Meet adorable pups looking for their forever homes!</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0V61H0L1200 0z" className="shape-fill3"></path>
          </svg>
        </div>
      </div> 
    {/* end of what's inside section - 3rd */}
    {/* Pricing section - 8th  {offer.offerPrice} */}
      {/* Modified Pricing section to only show annual plan */}
      <div id='start-now' className='container-fluid section-with-diagonal hero-background' style={{ background: 'var(--Black)' , backgroundImage: `url(${herov1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', zIndex:'1000' }}>
        <div className="row my-4 plan-section px-mobile">
          <div className="text-center mb-4">
            <h5 className='py-1' style={{color:'var( --Retriever-Gold)',}}>What's the Difference?</h5>
            <h2 className='py-2 section-title text-light'>DOGTV vs. Unleashed by DOGTV</h2>
          </div>
          {annualOffer && (
            <div className="row px-md-1 px-0 justify-content-center">
              
              <div className="col-md-6 mb-4 align-items-center" style={{display:'block'}}>
              <a className="card text-left select-offer" href="https://hello.dogtv.com/#start-now" style={{textDecoration: 'none'}} >
                  <div className="card-body d-flex flex-column">
                  <Zoom triggerOnce>
                    <img loading="lazy" decoding="async" src={PremiumPack} className="img-fluid zoom-img" alt="PremiumPack" style={{marginTop: '-50px'}}/>
                  </Zoom>
                    <h5 className="card-title pb-3" style={{ color: 'var(--Seadog-Blue)' }}>DOGTV - APP FOR DOGS</h5>
                    <h6 className="card-text price-text fs-1"  style={{fontWeight:800}}>Premium Ad-Free</h6>
                    <ul className="planlist text-black">
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Scientifically designed content for dogs</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>On Demand viewing for your dog</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Reduces anxiety, boredom & stress</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Helps with separation anxiety</li>
                    </ul>
                    <a href="https://hello.dogtv.com/#start-now" className="btn btn-primary d-flex mx-auto w-100">Get Started With 7-day Free Trial</a>
                    <p className='text-center py-2 fs-6'>&nbsp;</p>
                  </div>
                </a>
              </div>
              <div className="col-md-6 mb-4 align-items-center">
                <a className="card text-left select-offer" href="#channels" style={{textDecoration: 'none'}} >
                <div className="card-body d-flex flex-column">
                  <Zoom triggerOnce>
                    <img loading="lazy" decoding="async" src={freeChannel} className="img-fluid zoom-img" alt="freeChannel" style={{marginTop: '-50px'}}/>
                  </Zoom>
                    <h5 className="card-title pb-3" style={{ color: 'var(--Seadog-Blue)' }}>Ad-Supported</h5>
                    <h6 className="card-text price-text fs-1" style={{fontWeight:800}}>Free Channel</h6>
                    <ul className="planlist text-black">
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Streaming channel for dog lovers</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Watch for free 24/7</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Expert-led fun, educational shows</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Exclusive adoption and lifestyle shows</li>
                    </ul>
                    <a className="btn btn-primary d-flex mx-auto w-100" href="#channels" >Watch Free on These Platforms</a>
                    <p className='text-center py-2 fs-6'>More Platforms Launching Soon!</p>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end of Pricing section - 8th */}
      {/* as seen on section - 2nd */}
    <div id='channels' className='container-fluid' style={{ background: 'var(--White)' }}>
    <div className="row text-center py-5 logos-section px-mobile">
        <div className="col-12">
          <h5 className='py-0' style={{color:'var(--Seadog-Blue)',}}>Where Can You Watch Unleashed by DOGTV?</h5>
          <h3 className='text-center py-2'>Start streaming today on these free platforms</h3>
        </div>
        <div className="col-md-4 col-3 my-3"> 
          <Zoom triggerOnce><a href='https://www.amazon.com/Amazon-com-News-by-Fire-TV/dp/B0942ST2L1' target='_blank'>
          <img loading="lazy" decoding="async" width="346" height="100" src={firetv} className="img-fluid zoom-img" alt="firetv" /></a>
          </Zoom>
        </div>
        <div className="col-md-4 col-3 my-3"> 
          <Zoom triggerOnce><a href='https://www.amazon.com/Amazon-com-News-by-Fire-TV/dp/B0942ST2L1' target='_blank'>
          <img loading="lazy" decoding="async" width="346" height="100" src={tcl} className="img-fluid zoom-img" alt="tcl" /></a>
          </Zoom>
        </div>
        <div className="col-md-4 col-3 my-3"> 
          <Zoom triggerOnce><a href='https://www.tugotv.com/' target='_blank'>
          <img loading="lazy" decoding="async" width="346" height="100" src={tugo} className="img-fluid zoom-img" alt="tugo" /></a>
          </Zoom>
        </div>
        <div className="col-12">
          <p className='pb-3 mx-auto text-black fw-bold fs-6'>*TCL is Not currently available to stream in the U.S.</p>
        </div>
      </div>
      </div>
      {/* end of as seen on section - 2nd */}
      {/* two-column image left and text right - 3rd */}
      <div className='container-fluid' style={{ background: '#ffffff', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin:'0 auto', borderTop:'1px #efefef solid' }}>
        <div className=" fw ">
        <div className="col-md-6 d-flex justify-content-center order-2 order-md-1" style={{flexDirection:'column', padding:'7% 5%'}}>
            <h5 className="text-md-start text-center pb-3" style={{ color: 'var(--Painting-Pink)' }}>Lights, camera, action!</h5>
            <h2 className='pb-md-0 mx-auto-mobile text-black text-md-start text-center w-md-75' style={{maxWidth:'700px'}}>Your dog could be featured on our new show DOGSTAR</h2>
            <p className='py-4  mx-auto-mobile text-black text-md-start text-center' style={{maxWidth:'700px'}}>Whether they're chasing their tail, showing off tricks, or just being downright adorable, your pet could be the next big STAR! So, grab your phone, hit record, or upload a cherished video, and let the world fall in love with your furry superstar!</p>
            <div className="learn-more-section">
              <a 
                href="https://www.dogtv.com/dogstar" target='_blank'
                className="d-flex text-md-start text-center mx-auto-mobile" 
                style={{
                  color: 'var(--Seadog-Blue)', 
                  fontSize: '20px', 
                  fontWeight: '700', 
                  lineHeight: '1', 
                  textDecoration: 'none'
                }}
              >Submit your video &nbsp;&nbsp;
                <svg 
                  width="12" 
                  height="19" 
                  viewBox="0 0 12 19" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: showMoreText ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease'
                  }}
                >
                  <path d="M1.5293 1.9707L9.05845 9.49986L1.5293 17.029" stroke="#3D67A8" stroke-width="3"/>
                </svg>
              </a>
              
              {showMoreText && (
                <div className="more-text mt-3 animate__animated animate__fadeIn">
                  <p className="mx-auto-mobile text-black text-md-start text-center" style={{maxWidth:'700px'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 m-0 d-flex align-items-center justify-content-center order-1 order-md-2" style={{background: 'var(--Painting-Pink)',display:'block'}}>
          <Zoom triggerOnce>
            <img loading="lazy" decoding="async" width="1024" height="996" src={gold_standard_right} className="img-fluid animated-image" alt="landing-hero-v1" />
          </Zoom>
          </div>
        </div>
      </div>
      {/* end of two-column image left and text right - 3rd  */}
      {/* Blue CTA section - 5th */}
    <div className='container-fluid section-with-diagonal' style={{ background: 'var(--Seadog-Blue)' }}>
      <div className="row d-flex align-items-center justify-content-center py-5 px-mobile"> 
        <div className="col-md-9 text-md-start text-center text-light">
        <h4 className='w-md-75 pb-3'>Sign up for our newsletter and get notified of new platform launches happening all the time!</h4>
        </div>
        <div className="col-md-3 text-center align-items-center align-items-md-end text-light ">
          <a href="#join" className="btn btn-primary d-flex align-items-center mx-auto">Sign up for Newsletter</a>
        </div>
      </div>
    </div>
    {/* end of Blue CTA section - 5th */}
    </>
  );
};