import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/DOGTV_white_w_text_horizontal.png';
import unleashedlogo from '../assets/partnership/Unleashed_logo_white.svg';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    // change updated to allow logo to return use to subfolder home 20241205
    const pathSegments = location.pathname.split('/').filter(Boolean);
    
    if (pathSegments.length > 0) {
      const subfolder = pathSegments[0];
      const validSubfolders = [ 'dogday', 'try', 'chdi', 'petsvivo', 'paramount', 'paw30', 'pups30', 'promo256m', 'love', 'muttmadness', 'global', 'unleashed' ];

      // If current path is in a valid subfolder, navigate to its home
      if (validSubfolders.includes(subfolder)) {
        navigate(`/${subfolder}`);
        return;
      }
    }
        navigate('/');
  };

  useEffect(() => {
    const { state } = location;
    if (state && state.targetId) {
      const targetElement = document.getElementById(state.targetId);
      if (targetElement) {
        const offset = window.innerWidth <= 768 ? 50 : 80;
        const elementPosition = targetElement.offsetTop;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <header className="header sticky-top">
      <nav className="navbar navbar-expand-sm navbar-dark">
        <div className="container-fluid">
          <div onClick={handleClick} className="navbar-brand" style={{ cursor: 'pointer' }}>
            <img src={logo} alt="Logo" className="logo-img" />
          </div>
          <div onClick={handleClick} className="navbar-unleashed" style={{ cursor: 'pointer' }}>
            <img src={unleashedlogo} alt="Logo" className="logo-img" />
          </div>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item signin">
              <a href="https://watch.dogtv.com/" className="nav-link btn-24 sign-in-link" style={{ cursor: 'pointer' }}>
                <span className="sign-in-text">Sign-in</span>
                <svg className="sign-in-icon feather feather-user" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
              </a>
            </li>
            {/*
            <li className="nav-item startnow">
              <a href="/#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            */}
            <li className="nav-item startnow">
              <a href="#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onpetsmart">
              <a href="/petsmart/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item ondogday">
              <a href="/dogday/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item ontry">
              <a href="/try/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onchdi">
              <a href="#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onparamount">
              <a href="#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Get Deal Now</a>
            </li>
            <li className="nav-item onpups30">
              <a href="/pups30/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onpaw30">
              <a href="/paw30/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onpromo256m">
              <a href="/promo256m/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Redeem Offer</a>
            </li>
            <li className="nav-item ontestimonials">
              <a href="/testimonials/#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onlove">
              <a href="#start-now" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Get Deal Now</a>
            </li>
            <li className="nav-item onmuttmadness">
              <a href="/muttmadness/checkout" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Redeem Offer</a>
            </li>
            <li className="nav-item onglobal">
              <a href="https://watch.dogtv.com/registration?purchaseBundleId=tv.accedo.one.partner.cleeng&purchaseToken=appaglobal24" className="nav-link btn-12" style={{ cursor: 'pointer' }}>Start For Free</a>
            </li>
            <li className="nav-item onunleashed">
              <a href="#join" className="nav-link btn-12 btn-primary" style={{ cursor: 'pointer' }}>Sign-up for Newsletter</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;