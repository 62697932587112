import React, { useState, useEffect } from 'react';
import Courtney from '../assets/testimonials/Courtney.jpg';
import featured_vid_image from '../assets/partnership/all-day-long.jpg'

interface Video {
  id: string;
  thumbnail: string;
  title: string;
  youtubeId: string;
}

const FeaturedVideo: React.FC = () => {
  const [activeVideo, setActiveVideo] = useState<string | null>(null);
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth >= 768 && window.innerWidth < 992
  });
  
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobile: window.innerWidth < 768,
        isTablet: window.innerWidth >= 768 && window.innerWidth < 1025
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getFontSize = () => {
    if (screenSize.isMobile) return '24px';
    if (screenSize.isTablet) return '27px';
    return '30px';
  };

  const videos: Video[] = [
    {
      id: 'video1',
      thumbnail: featured_vid_image,
      title: " ",
      youtubeId: 'y7EjwZowyJc'
    }
  ];
  
  const handleThumbnailClick = (videoId: string): void => {
    setActiveVideo(videoId);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setActiveVideo(null);
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <div className="container-fluid px-0">
        <div className="row" style={{ gap: '20px 0' }}>
          {videos.map((video: Video) => (
            <div key={video.id} className="col-md-8 col-12 mx-auto">
              <div 
                style={{
                  border: '3px solid var(--White)',
                  borderRadius: '40px',
                  overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 = 0.5625 = 56.25%)
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${video.thumbnail})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleThumbnailClick(video.id)}
                >
                  <div 
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{ display: 'none', alignItems: 'center', gap: '12px' }}>
                      <svg
                        width="34"
                        height="34"
                        className="text-white"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M8 5v14l11-7z" />
                      </svg>
                      <h4 style={{ 
                        fontSize: getFontSize(), 
                        fontWeight: 600, 
                        color: 'white', 
                        margin: 0,
                        lineHeight: 1
                      }}>
                        {video.title}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {activeVideo && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999
          }}
          onClick={handleCloseModal}
        >
          <div 
            style={{
              position: 'relative',
              width: '90%',
              maxWidth: '1024px',
              aspectRatio: '16/9'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <iframe
              src={`https://www.youtube.com/embed/${videos.find(v => v.id === activeVideo)?.youtubeId}?autoplay=1`}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '20px'
              }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FeaturedVideo;